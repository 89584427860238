.maxWidth{
  max-width: 1440px;
  margin: auto;
  padding: 0 20px;
}

.mainLogo{
  width: auto;
  height: auto;
  max-height: 88px;
  object-fit: contain;
}