*, ::after, ::before{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", Helvetica, sans-serif;
}

:root{
  --red: #861619;
  --orange: #E46429;
  --padVertical: 40px;
}

a{
  display: inline-block;
  transition: opacity 0.2s;
}

a:hover{
  opacity: 0.8;
}

@media screen and (max-width: 767px) {
  :root{
    --padVertical: 24px;
  }
}